<template>
  <div class="main-content" style="
    padding-bottom: 10vh !important
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      width: 100%;
      background-position: center;
    ">
    <div class="dashboard-banner position-relative">
      <div class="d-flex justify-content-center align-items-end" style="padding-bottom: 14vh !important;">
        <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("member") }}</h4>
      </div>
    </div>

    
    <SponsorTree></SponsorTree>
  </div>
</template>

<script>
import SponsorTree from "./memberTree.vue";
// import PlacementTree from "./placementTree.vue";
export default {
  components: {
    SponsorTree,
    // PlacementTree,
  },
};
</script>